<template>
  <div class="bigDiv">
    <img :src="mag_Url" alt="" class="bigImg" />

    <van-overlay :show="ISshow" @click="ISshow = false">
      <div class="wrapper" @click.stop>
        <div class="block" />
      </div>
    </van-overlay>
    <img
      src="https://oss.hshwhkj.com/uploadfile/20241114/715623340576550912.jpg"
      alt=""
      class="bgc"
    />
    <img :src="mag_Url" alt="" class="mag" />
  </div>
</template>

<script>
import { getTxQrcode } from "../../api/components";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      ISshow: true,

      mag_Url: "",
    };
  },
  methods: {
    async getTxQrcode() {
      const { data } = await getTxQrcode();
      console.log(data);
      this.mag_Url = data.data.img;
      this.ISshow = false;
    },
  },
  mounted() {
    this.getTxQrcode();
  },
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang="less">
.bigDiv {
  width: 100%;
  height: 667px;

  background-color: #f2fff6;
  .bigImg {
    position: fixed;
    z-index: 999;
    width: 0;
    height: 0;
    opacity: 0; /* 使盒子完全透明 */
  }
  .bgc {
    width: 375px;
    height: 810px;

    object-fit: contain;
    position: fixed;
    top: 0;
  }
  .mag {
    position: relative;
    width: 280px;
    height: 280px;
    left: 50%;
    top: 39.5%;
    z-index: 9999;
    transform: translate(-50%);
  }
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 750px;
    height: 812px;

    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
    .bgc {
      width: 750px;
      height: 812px;
      object-fit: contain;
      position: relative;
      top: 0;
    }
    .mag {
      position: relative;
      width: 200px;
      height: 200px;
      left: 50%;
      top: -66%;
      transform: translate(-50%);
    }
  }
}
</style>
